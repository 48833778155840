html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-color: #1E1E1E !important;
  color: white !important;
}

.primary {
  color: #C778DD !important;
}

button {
  background-color: #1E1E1E !important;
  color: #fff !important;
  border: 1px solid #C778DD !important;
  border-radius: 0px !important;
}

.my-nav li::before {
  content: "#";
  color: #C778DD;
}

.my-nav ul li {
  display: inline;
  padding: 0px 10px;
}

.my-nav ul li:last-child {
  padding-right: 0px;
}

.quote-text {
  border: 1px solid white;
  width: fit-content;
  height: fit-content;
  position: relative;
  padding: 30px;

}


.quote-text h2::before {
  content: '“';
  color: #C778DD;
  background-color: #1E1E1E;
  font-size: 70px;
  position: absolute;
  top: -30px;
  left: 10px;
  display: inline-block;
  width: 30px;
  max-height: 40px;
}

.quote-text h2::after {
  content: '”';
  color: #C778DD;
  background-color: #1E1E1E;
  font-size: 70px;
  position: absolute;
  bottom: -54px;
  right: 10px;
  height: fit-content;
}

.h1_before::before {
  content: "#";
  color: #C778DD;
  padding-right: 2px;
}

.h1_before {
  margin: auto 0;
}

.card {
  background-color: #1E1E1E !important;
  color: white !important;
  border: 1px solid #ABB2BF !important;
  border-radius: 0px !important;
}

.card-body {
  border-bottom: 1px solid white;
}

.line-75 {
  width: 75% !important;

}

hr {
  width: 75%;
  height: 100%;
  border: 3px solid #C778DD !important;
}

.center {
  margin: auto 0;
}

.rtl {
  direction: rtl;
}

.rtl .card {
  direction: ltr;
}

.link {
  text-decoration: none !important;
  color: inherit !important;
}

.link:hover {
  text-decoration: underline !important;
  color: #C778DD !important;
}

.h1_slash::before {
  content: "/";
  color: #C778DD;
  padding-right: 2px;
}

.form-control {
  background-color: #1E1E1E !important;
  color: white !important;
  border: 1px solid #ABB2BF !important;
  border-radius: 0px !important;
}

.form-control::placeholder {
  color: #ABB2BF !important;
}

body {
  font-family: 'Fira Code', monospace !important;
}

p {
  color: gray;
}

.mobile {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .mobile {
    display: block;
    margin-top: 7px;

  }

  .desktop {
    display: none;
  }
}

.first-line {
  width: 24px;
  height: 5px;
  background-color: whitesmoke;
  margin: 0 auto;
}

.second-line {
  width: 16px;
  height: 5px;
  background-color: whitesmoke;
  margin-top: 2px;
  margin-left: 8px;
}

.hidden {
  display: none;
}

.menu {
  top: 0px;
  left: 0px;
  height: 100vh;
  position: absolute;
  width: 100vw;
  background-color: #1E1E1E;
  margin: auto;
  margin: 0px;
  padding: 10px;
  z-index: 1;
}

.hover:hover {
  cursor: pointer;
}

.menu ul li {
  display: block;
}

.menu-container {
  margin: auto;
}

/* Styles for .limit scrollbar */
/* Styles for .limit scrollbar */
.limit {
  overflow-y: auto;
    height: 200px;

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* WebKit scrollbar styles */
.limit::-webkit-scrollbar {
  width: 10px;
}

.limit::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.limit::-webkit-scrollbar-thumb {
  background: #C778DD;
  /* Updated color for the scrollbar handle */
}

.limit::-webkit-scrollbar-thumb:hover {
  background: #9558B2;
  /* Adjusted color on hover */
}
.card{
  width: 100%;
}

.message.ai em {
  font-style: normal;
  opacity: 0.7;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}


/* Container for profile image */
.profile-container {
  position: relative;
  width: 300px; /* Adjust size as needed */
  height: 300px;
  margin: 50px auto;
  background-color: #1E1E1E; /* Dark background */
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 20px 5px rgba(199, 120, 221, 0.7); /* Soft neon glow */
}

.profile-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* Neon line effect */
.profile-container::before, .profile-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0px 0px 30px 5px #C778DD;
  z-index: -1; /* Keep it behind the profile image */
}

.profile-container::before {
  transform: rotate(45deg); /* Create diagonal line */
  background: linear-gradient(135deg, rgba(199, 120, 221, 0.5) 50%, transparent 50%);
}

.profile-container::after {
  transform: rotate(-45deg);
  background: linear-gradient(135deg, transparent 50%, rgba(199, 120, 221, 0.5) 50%);
}


.career-history {
  background-color: #1E1E1E;
  color: white;
  
  font-family: 'Fira Code', monospace;
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #C778DD;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.timeline-item {
  border: 1px solid #ABB2BF;
  padding: 1.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.timeline-item:hover {
  box-shadow: 0 0 10px rgba(199, 120, 221, 0.5);
}

.timeline-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(199, 120, 221, 0.1) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.timeline-item:hover::before {
  opacity: 1;
}

.item-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #C778DD;
  margin-bottom: 0.5rem;
}

.item-company {
  font-size: 1rem;
  color: white;
  margin-bottom: 0.25rem;
}

.item-period {
  font-size: 0.875rem;
  color: #ABB2BF;
  margin-bottom: 0.75rem;
}

.item-description {
  font-size: 0.9rem;
  color: gray;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .section-title {
    font-size: 1.5rem;
  }

  .timeline-item {
    padding: 1rem;
  }
}