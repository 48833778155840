.chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #8a2be2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(138, 43, 226, 0.4);
    transition: all 0.3s ease;
    z-index: 1000;
    color: #fff;
    font-size: 24px;
}

.chat-icon:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 15px rgba(138, 43, 226, 0.6);
}

.chatbox-container {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 350px;
    height: 500px;
    background-color: #1a1a1a;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 1000;
    border: 1px solid #8a2be2;
}

.chatbox {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #8a2be2;
    color: white;
}

.chat-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    scroll-behavior: smooth;
}

.message {
    margin-bottom: 15px;
    max-width: 80%;
    padding: 10px 15px;
    border-radius: 20px;
}

.message.user {
    align-self: flex-end;
    background-color: #8a2be2;
    color: #fff;
}

.message.ai {
    align-self: flex-start;
    background-color: #333;
    color: #fff;
}

.input-group {
    display: flex;
    padding: 10px;
    background-color: #252525;
}

.input-group input {
    flex-grow: 1;
    border: 1px solid #8a2be2;
    border-radius: 20px;
    padding: 10px 15px;
    margin-right: 10px;
    background-color: #1a1a1a;
    color: #fff;
}

.input-group input::placeholder {
    color: #888;
}

.input-group button {
    background-color: #8a2be2;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.input-group button:hover {
    background-color: #9d4edd;
}

.typing-animation {
    display: inline-block;
}

.typing-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 4px;
    animation: typingAnimation 1.4s infinite ease-in-out;
}

.typing-dot:nth-child(1) { animation-delay: 0s; }
.typing-dot:nth-child(2) { animation-delay: 0.2s; }
.typing-dot:nth-child(3) { animation-delay: 0.4s; }

@keyframes typingAnimation {
    0%, 60%, 100% { transform: translateY(0); }
    30% { transform: translateY(-6px); }
}

@media (max-width: 768px) {
    .chatbox-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .chat-icon {
        bottom: 10px;
        right: 10px;
    }
}